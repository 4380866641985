<template>
  <div class="container">
    <van-loading
      class="loading"
      color="#9d1d22"
      text-color="#9d1d22"
      v-if="loading"
      size="24px"
      >加载中...</van-loading
    >
    <div v-if="loading == false">
      <van-sticky>
        <van-nav-bar
          :title="title"
          left-arrow
          @click-left="$router.replace({ path: '/worklog' })"
        />
      </van-sticky>
      <van-form @submit="onSubmit" style="padding-bottom: 60px">
        <QuillEditor
          ref="editor2"
          v-model="worklog.detailsHtml"
          :contentTmpType="contentTmpType"
          @onInputHandler="onInput"
        />
        <div style="padding: 10px">
          <van-row>
            <van-col span="4" style="line-height: 2">分享到：</van-col>
            <van-col span="8"
              ><van-checkbox
                v-model="shareInfo.isSelectCompany"
                @click="onSelectDepartment(0)"
                >全公司</van-checkbox
              ></van-col
            >
            <van-col span="12">
              <van-checkbox
                style="margin-left: 20px"
                v-model="shareInfo.isSelectCenter"
                v-if="userCenterDepartment"
                @click="onSelectDepartment(1)"
                >{{
                  userCenterDepartment.name.replace("经管委员会", "")
                }}</van-checkbox
              ></van-col
            >
          </van-row>
          <van-row
            style="margin: 5px 0px 5px 0px"
            v-if="mangedHRDepartments.length > 0"
            ><van-col span="4"></van-col>
            <van-col span="8">
              <van-checkbox
                v-model="shareInfo.isSelectBbs"
                :disabled="Boolean(worklog.shareToBbsId)"
                >中酿心声
                <img :src="bbsIcon" class="van-icon__image" /></van-checkbox
            ></van-col>
            <van-col span="16"></van-col>
          </van-row>
        </div>
        <van-field label="设为部门模板" v-if="showSetTemplate">
          <template #input>
            <van-checkbox-group
              v-model="templateDepartmentIds"
              direction="horizontal"
            >
              <van-checkbox
                v-for="mangedHRDepartment in mangedHRDepartments"
                :key="mangedHRDepartment.id"
                :name="'templateDepartmentIds' + mangedHRDepartment.id"
                shape="square"
                >{{ mangedHRDepartment.name }}</van-checkbox
              >
            </van-checkbox-group>
          </template>
        </van-field>
        <!--上传预览-->
        <div class="van-hairline--top-bottom" v-show="imagefileList.length > 0">
          <van-row>
            <van-col span="24">
              <van-uploader
                multiple
                v-model="imagefileList"
                :max-count="20"
                :after-read="afterReadImage"
              />
            </van-col>
          </van-row>
        </div>
        <div
          style="
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 5px 5px 5px 5px;
            background-color: #ededed;
          "
          class="addbottom"
        >
          <van-row>
            <van-col span="12">
              <van-uploader
                multiple
                :max-count="20"
                :after-read="afterReadImageOut"
              >
                <div>
                  <van-icon size="30" name="/image/image.png" />
                </div>
              </van-uploader>
            </van-col>

            <van-col span="12">
              <div style="padding-right: 10px">
                <van-button
                  size="small"
                  round
                  block
                  type="info"
                  native-type="submit"
                  >提交</van-button
                >
              </div>
            </van-col>
          </van-row>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { Toast, Dialog } from "vant";
import { mapGetters } from "vuex";
import QuillEditor from "../components/quillEditor";
import { getTemplate, add, addTemplate, get, shareToBbs } from "@/api/worklog";
import { uploadfile } from "@/api/file";

export default {
  data() {
    return {
      bbsIcon:
        "http://kmfile.bjznpz.com/wwwroot/zn_files/210326/fe7adea1aeff434b9dc6e804b42caa86.png",
      imagefileList: [],
      title: "",
      loading: true,
      editoroninput: false,
      templateDepartmentIds: [],
      mangedHRDepartments: [],
      templateMatchs: [],
      worklog: {
        id: null,
        templateId: null,
        detailsHtml: "",
        shareToBbsId: "",
      },
      userCenterDepartment: null,
      shareInfo: {
        shareType: 12,
        isSelectCompany: true,
        isSelectCenter: false,
        isSelectBbs: false,
      },
    };
  },
  components: {
    QuillEditor,
  },
  created() {
    this.onLoad();
  },
  watch: {
    $route(to, from) {
      //监听路由是否变化
      if (to.path == "/worklog/add" && to.query != from.query) {
        this.loading = true;
        this.onLoad();
      }
    },
  },
  mounted() {},
  computed: {
    showSetTemplate() {
      return false;
      //注释设置部门模板功能
      // if (
      //   !this.$store.state.user.mangedHRDepartmentIds ||
      //   this.$store.state.user.mangedHRDepartmentIds.length <= 0
      // )
      //   return false;
      // return true;
    },
    contentTmpType() {
      return (
        "worklog_" +
        (this.worklog.id || "") +
        "-template_" +
        (this.worklog.templateId || "")
      );
    },
  },
  methods: {
    ...mapGetters(["getAllDepartments"]),
    onInput: function (input) {
      this.editoroninput = input;
    },
    onSelectDepartment(type) {
      if (type == 0) {
        this.shareInfo.isSelectCenter = false;
      } else {
        this.shareInfo.isSelectCompany = false;
      }
    },
    async onLoad() {
      const departments = await this.getAllDepartments();
      const centerDepartment = await this.findCurrentUserCenterDepartment(
        departments
      );
      if (centerDepartment) {
        this.userCenterDepartment = centerDepartment;
      }
      this.templateDepartmentIds = [];
      this.mangedHRDepartments = [];
      if (
        this.$store.state.user.mangedHRDepartmentIds &&
        this.$store.state.user.mangedHRDepartmentIds.length > 0
      ) {
        this.mangedHRDepartments = departments.filter(
          (x) =>
            this.$store.state.user.mangedHRDepartmentIds.indexOf(x.id) != -1
        );
      }
      this.templateMatchs = [];
      this.worklog = {
        id: this.$route.query.id,
        templateId: this.$route.query.templateId,
        detailsHtml: "",
      };
      if (!this.worklog.id) {
        this.title = "写总结";
        await this.getTemplateData();
        this.loading = false;
        return;
      }
      this.title = "修改总结";
      await this.get();
      await this.getTemplateData();
      this.loading = false;
    },
    async getTemplateData() {
      if (this.worklog.templateId) {
        var d = await getTemplate({ id: this.worklog.templateId });
        if (d.data.success && d.data.data) {
          if (d.data.data.title) {
            if (this.worklog.id) {
              this.title = "修改" + d.data.data.title;
            } else {
              this.title = "写" + d.data.data.title;
            }
          }

          var template = d.data.data.detailsHtmlTemplate || "";
          this.worklog.templateId = d.data.data.id;
          if (!this.worklog.id) this.worklog.detailsHtml = template;

          if (/>([^>]+?：<?)</gi.test(template)) {
            var matchs = template.match(/>([^>]+?：<?)</gi);
            matchs = matchs.map((x) =>
              x
                .replace(">", "")
                .replace("<", "")
                .replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&")
            );
            this.templateMatchs = matchs;
          }
        }
      }
    },
    async get() {
      const d = await get({ id: this.worklog.id });
      if (d.data.success) {
        this.worklog.templateId = d.data.data.templateId;
        this.worklog.detailsHtml = d.data.data.detailsHtml;
        this.worklog.shareToBbsId = d.data.data.shareToBbsId;
        this.shareInfo.isSelectCompany =
          d.data.data.shareDepartmentIds.indexOf(100) != -1;
        this.shareInfo.isSelectCenter =
          this.userCenterDepartment &&
          d.data.data.shareDepartmentIds.indexOf(
            this.userCenterDepartment.id
          ) != -1;
      }
    },
    afterReadImage: async function (files) {
      Toast("文件上传中");
      //多张图片循环上传
      var filelist = [];

      if (Object.prototype.toString.call(files) === "[object Array]") {
        files.reverse();//根据选择顺序上传
        files.forEach((element) => {
          filelist.push(element.file);
        });
      } else {
        filelist.push(files.file);
      }
      for (let index = 0; index < filelist.length; index++) {
        let formData = new FormData();
        formData.append("file", filelist[index]);
        let aresult = await uploadfile(formData);
        let url = aresult.data; // Get url from response
        this.imagefileList[
          this.imagefileList.length - filelist.length + index
        ].url = url;
      }

      Toast("文件上传成功");
    },
    //点击下方的上传按钮
    afterReadImageOut: async function (files) {
      Toast("文件上传中");
      //多张图片循环上传
      var filelist = [];
      if (Object.prototype.toString.call(files) === "[object Array]") {
        files.reverse();//根据选择顺序上传
        files.forEach((element) => {
          filelist.push(element.file);
        });
      } else {
        filelist.push(files.file);
      }

      for (let index = 0; index < filelist.length; index++) {
        let formData = new FormData();
        formData.append("file", filelist[index]);
        let aresult = await uploadfile(formData);

        let url = aresult.data; // Get url from response
        this.imagefileList.push({
          url: url,
          imageFit: "contain",
          previewSize: 80,
        });

        if (this.imagefileList.length == 20) {
          break;
        }
      }

      Toast("文件上传成功");
    },
    async onSubmit() {
      if (this.templateDepartmentIds.length > 0) {
        await this.onSaveTemplate();
      } else {
        await this.onSave();
      }
    },
    async onSave() {
      this.worklog.detailsHtml = this.$refs.editor2.content;
      if (this.worklog.detailsHtml.indexOf('img src="data:') != -1) {
        Toast("不允许粘贴图片,请手动上传");
        return;
      }

      if (this.worklog.detailsHtml.length < 1) {
        Toast("请填写详细内容");
        return;
      }

      if (this.imagefileList.length > 0) {
        this.imagefileList.forEach((element) => {
          this.worklog.detailsHtml += "<img src='" + element.url + "'/>";
        });
      }

      if (this.shareInfo.isSelectCompany) this.worklog.shareDepartmentId = 100;
      if (this.shareInfo.isSelectCenter)
        this.worklog.shareDepartmentId = this.userCenterDepartment.id;

      const noMatchs = [];
      if (this.templateMatchs.length > 0) {
        for (let index = 0; index < this.templateMatchs.length; index++) {
          var reg = new RegExp(this.templateMatchs[index], "ig");
          const b = reg.test(this.worklog.detailsHtml);
          if (!b) {
            noMatchs.push(this.templateMatchs[index]);
            console.log(noMatchs);
            continue;
          }
        }
      }
      if (noMatchs.length > 0) {
        Dialog.confirm({
          title: "未匹配到以下格式内容，是否确定提交？",
          message: noMatchs.join("<br/>"),
        }).then(async () => {
          const d = await add(this.worklog);
          console.log(d);
          if (d.data.success) {
            if (!this.worklog.shareToBbsId && this.shareInfo.isSelectBbs) {
              await shareToBbs({
                id: d.data.id,
              });
            }

            if (
              d.data.scoreMessage !== undefined &&
              d.data.scoreMessage != ""
            ) {
              Toast({
                message: d.data.scoreMessage,
                icon: "/image/signin_point_add.png",
              });
            } else {
              Toast("提交成功");
            }
            //额外积分提醒
            if (
              d.data.scoreMessage2 !== undefined &&
              d.data.scoreMessage2 != ""
            ) {
              setTimeout(() => {
                Toast({
                  message: d.data.scoreMessage2,
                  icon: "/image/qiang.png",
                });
              }, 2000);
            } else if (d.data.continuous) {
              setTimeout(() => {
                Toast({
                  message: "加油",
                  icon: "/image/qiang.png",
                });
              }, 2000);
            }

            this.worklog.detailsHtml = "";
            this.$router.back();
          } else {
            Toast(d.data.errorMessage);
          }
        });
        return;
      }
      this.worklog.templateId =
        this.worklog.templateId || "00000000-0000-0000-0000-000000000000";
      const d = await add(this.worklog);
      if (d.data.success) {
        if (!this.worklog.shareToBbsId && this.shareInfo.isSelectBbs) {
          await shareToBbs({
            id: d.data.id,
          });
        }

        if (d.data.scoreMessage !== undefined && d.data.scoreMessage != "") {
          Toast({
            message: d.data.scoreMessage,
            icon: "/image/signin_point_add.png",
          });
        } else {
          Toast("提交成功");
        }
        //额外积分提醒
        if (d.data.scoreMessage2 !== undefined && d.data.scoreMessage2 != "") {
          setTimeout(() => {
            Toast({
              message: d.data.scoreMessage2,
              icon: "/image/qiang.png",
            });
          }, 2000);
        } else if (d.data.continuous) {
          setTimeout(() => {
            Toast({
              message: "加油",
              icon: "/image/qiang.png",
            });
          }, 2000);
        }
        this.worklog.detailsHtml = "";
        this.$router.back();
      } else {
        Toast(d.data.errorMessage);
      }
    },
    async onSaveTemplate() {
      var detailsHtmlTemplate = this.$refs.editor2.content;
      if (detailsHtmlTemplate.indexOf('img src="data:') != -1) {
        Toast("不允许粘贴图片,请手动上传");
        return;
      }

      if (detailsHtmlTemplate.length < 1) {
        Toast("请填写详细内容");
        return;
      }

      if (!/>([^>]+?：<?)</gi.test(detailsHtmlTemplate)) {
        Toast("未匹配到模板格式，请重新输入");
        return;
      }

      var matchs = detailsHtmlTemplate.match(/>([^>]+?：<?)</gi);
      matchs = matchs.map((x) => x.replace(">", "").replace("<", ""));
      Dialog.confirm({
        title: "匹配到的格式，请确认后继续",
        message: matchs.join("<br/>"),
      }).then(async () => {
        const d = await addTemplate({
          detailsHtmlTemplate: detailsHtmlTemplate,
        });
        if (d.data.success) {
          Toast("提交成功");
          this.$router.back();
        } else {
          Toast(d.data.errorMessage);
        }
      });
    },
    async findCurrentUserCenterDepartment(departments) {
      const departmentName = this.$store.state.user.department;
      const department = departments.filter((x) =>
        x.path.replace(/\//g, "").endsWith(departmentName)
      )[0];
      return departments.filter(
        (x) => department.path.startsWith(x.path) && x.levelIndex == 2
      )[0];
    },
  },
};
</script>

<style lang="less" scoped>
.loading {
  text-align: center;
}
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  /deep/.van-checkbox {
    margin-top: 5px;
  }
  /deep/ .btn-box {
    margin-top: 25px;
  }
}
</style>